
import { Component, Prop, Vue } from 'vue-property-decorator'
import { CompetitionType, CompetitionCreationVals } from 'shared/types/competition'
import { LogBookUser, LogBookUserState } from 'shared/state'
import { Competitions, CompetitionsState } from 'shared/state/Competitions'
import date from 'date-and-time'
import { RaceServer } from 'shared/types/logBook'
import ServerSelect from './ServerSelect.vue'

@Component({ components: {} })
export default class NewCompetitionForm extends Vue {
	@Prop() creating!: boolean
	@LogBookUserState() logBookUserInfo
	@CompetitionsState('servers') raceServers!: RaceServer[]
	form = {
		name: '',
		raceServer: 'rs1.ergrace.com',
		start: new Date()
	}

	show: boolean = true

	get options() {
		return this.raceServers.map(s => ({ value: s.name, text: `${s.name} (${s.type}) ` }))
	}
	get isAdmin() {
		return this.logBookUserInfo.roles.includes('ergrace-admin')
	}

	onSubmit(evt) {
		evt.preventDefault()

		const e: CompetitionCreationVals = {
			timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
			name: this.form.name,
			start: this.form.start instanceof Date ? date.format(this.form.start, 'YYYY-MM-DD') : this.form.start
		}
		const generalServers = this.raceServers.filter(s => s.type === 'general')
		// tslint:disable-next-line:no-bitwise
		const balance = ~~(Math.random() * generalServers.length)
		const server = this.isAdmin ? this.form.raceServer : generalServers[balance].name ? generalServers[balance].name : ''

		this.$emit('createCompetition', e, this.form.raceServer)

		this.form = {
			name: '',
			raceServer: generalServers[0] ? generalServers[0].name : '',
			start: new Date()
		}
	}
}
