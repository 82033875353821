import { render, staticRenderFns } from "./RaceDetails.vue?vue&type=template&id=49181d78&scoped=true&"
import script from "./RaceDetails.vue?vue&type=script&lang=ts&"
export * from "./RaceDetails.vue?vue&type=script&lang=ts&"
import style0 from "./RaceDetails.vue?vue&type=style&index=0&id=49181d78&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49181d78",
  null
  
)

export default component.exports